@font-face {
  font-family: 'MARSO';
  src:
    url('fonts/MARSO.woff2?9e12p3') format('woff2'),
    url('fonts/MARSO.ttf?9e12p3') format('truetype'),
    url('fonts/MARSO.woff?9e12p3') format('woff'),
    url('fonts/MARSO.svg?9e12p3#MARSO') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="marso-icon-"], [class*=" marso-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MARSO' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.marso-icon-spare-wheel:before {
  content: "\e96b";
}
.marso-icon-screw-nut-3d:before {
  content: "\e963";
}
.marso-icon-wheel-3d:before {
  content: "\e969";
}
.marso-icon-DOT:before {
  content: "\e950";
}
.marso-icon-retread:before {
  content: "\e951";
}
.marso-icon-wheel_service:before {
  content: "\e94f";
}
.marso-icon-sq_right:before {
  content: "\e94d";
}
.marso-icon-sq_wrong:before {
  content: "\e94e";
}
.marso-icon-minus:before {
  content: "\e949";
}
.marso-icon-plus1:before {
  content: "\e94a";
}
.marso-icon-right:before {
  content: "\e94b";
}
.marso-icon-wrong:before {
  content: "\e94c";
}
.marso-icon-coll:before {
  content: "\e90c";
}
.marso-icon-tomlo_nelkul:before {
  content: "\e945";
}
.marso-icon-forklift:before {
  content: "\e946";
}
.marso-icon-kart02:before {
  content: "\e902";
}
.marso-icon-all_season:before {
  content: "\e903";
}
.marso-icon-filled_truck:before {
  content: "\e904";
}
.marso-icon-notification:before {
  content: "\e905";
}
.marso-icon-summer:before {
  content: "\e906";
}
.marso-icon-winter:before {
  content: "\e907";
}
.marso-icon-SHOP-kosr:before {
  content: "\e908";
}
.marso-icon-kle_white:before {
  content: "\e900";
  color: #fff;
}
.marso-icon-rim-fringe-protector:before {
  content: "\e901";
}
.marso-icon-account:before {
  content: "\e909";
}
.marso-icon-address:before {
  content: "\e90a";
}
.marso-icon-agri:before {
  content: "\e90b";
}
.marso-icon-caffee:before {
  content: "\e90d";
}
.marso-icon-car:before {
  content: "\e90e";
}
.marso-icon-car_service:before {
  content: "\e90f";
}
.marso-icon-cargo:before {
  content: "\e910";
}
.marso-icon-cargo21:before {
  content: "\e911";
}
.marso-icon-cargo2:before {
  content: "\e912";
}
.marso-icon-cetelem_01:before {
  content: "\e913";
}
.marso-icon-cetelem_02:before {
  content: "\e914";
}
.marso-icon-clock:before {
  content: "\e915";
}
.marso-icon-creditcard:before {
  content: "\e916";
}
.marso-icon-customer_Service:before {
  content: "\e917";
}
.marso-icon-delivery:before {
  content: "\e918";
}
.marso-icon-discount:before {
  content: "\e919";
}
.marso-icon-download:before {
  content: "\e91a";
}
.marso-icon-dump:before {
  content: "\e91b";
}
.marso-icon-energy_label:before {
  content: "\e91c";
}
.marso-icon-expert:before {
  content: "\e91d";
}
.marso-icon-facebook:before {
  content: "\e91e";
}
.marso-icon-fuel_label:before {
  content: "\e91f";
}
.marso-icon-gift:before {
  content: "\e920";
}
.marso-icon-hamburger_menu:before {
  content: "\e922";
}
.marso-icon-hubcaps .path1:before {
  content: "\e923";
  color: rgb(247, 247, 247);
}
.marso-icon-hubcaps .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path3:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path4:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.marso-icon-hubcaps .path5:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path6:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.marso-icon-hubcaps .path7:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path8:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.marso-icon-hubcaps .path9:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path10:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.marso-icon-hubcaps .path11:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path12:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path13:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path14:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path15:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path16:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path17:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path18:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path19:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path20:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path21:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path22:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path23:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path24:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-hubcaps .path25:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.marso-icon-hubcaps .path26:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(230, 230, 230);
}
.marso-icon-info:before {
  content: "\e93d";
}
.marso-icon-location:before {
  content: "\e93e";
}
.marso-icon-mail:before {
  content: "\e93f";
}
.marso-icon-money:before {
  content: "\e940";
}
.marso-icon-money_transfer:before {
  content: "\e941";
}
.marso-icon-moto:before {
  content: "\e942";
}
.marso-icon-news:before {
  content: "\e943";
}
.marso-icon-noise_label:before {
  content: "\e944";
}
.marso-icon-on_map:before {
  content: "\e953";
}
.marso-icon-open:before {
  content: "\e954";
}
.marso-icon-package:before {
  content: "\e955";
}
.marso-icon-phone:before {
  content: "\e956";
}
.marso-icon-promotion_gift:before {
  content: "\e957";
}
.marso-icon-promotions:before {
  content: "\e958";
}
.marso-icon-psg:before {
  content: "\e959";
}
.marso-icon-psg1:before {
  content: "\e95a";
}
.marso-icon-pumpkin:before {
  content: "\e95b";
}
.marso-icon-rain_label:before {
  content: "\e95c";
}
.marso-icon-refresh:before {
  content: "\e95d";
}
.marso-icon-reinforced:before {
  content: "\e95e";
}
.marso-icon-runflat:before {
  content: "\e95f";
}
.marso-icon-search:before {
  content: "\e960";
}
.marso-icon-service:before {
  content: "\e961";
}
.marso-icon-shopping_cart:before {
  content: "\e962";
}
.marso-icon-susp .path1:before {
  content: "\e964";
}
.marso-icon-susp .path2:before {
  content: "\e965";
  margin-left: -1em;
}
.marso-icon-susp .path3:before {
  content: "\e966";
  margin-left: -1em;
}
.marso-icon-susp .path4:before {
  content: "\e967";
  margin-left: -1em;
}
.marso-icon-susp .path5:before {
  content: "\e968";
  margin-left: -1em;
}
.marso-icon-SUV:before {
  content: "\e97e";
}
.marso-icon-SUV01:before {
  content: "\e97f";
}
.marso-icon-suv2:before {
  content: "\e980";
}
.marso-icon-test_winner:before {
  content: "\e981";
}
.marso-icon-timer:before {
  content: "\e982";
}
.marso-icon-toy:before {
  content: "\e983";
}
.marso-icon-trash:before {
  content: "\e984";
}
.marso-icon-truck:before {
  content: "\e985";
}
.marso-icon-tyre:before {
  content: "\e986";
}
.marso-icon-tyre_date:before {
  content: "\e987";
}
.marso-icon-tyre_insurance:before {
  content: "\e988";
}
.marso-icon-tyre_safe:before {
  content: "\e989";
}
.marso-icon-tyre_service:before {
  content: "\e98a";
}
.marso-icon-tyrestack:before {
  content: "\e98b";
}
.marso-icon-UHP:before {
  content: "\e98c";
}
.marso-icon-wheel:before {
  content: "\e98d";
}
.marso-icon-wifi:before {
  content: "\e98e";
}
.marso-icon-document-edit:before {
  content: "\e948";
}
.marso-icon-flickr:before {
  content: "\e921";
}
.marso-icon-folder-upload:before {
  content: "\e96a";
}
.marso-icon-files:before {
  content: "\e952";
}
.marso-icon-eye:before {
  content: "\e947";
}
.marso-icon-plus:before {
  content: "\f067";
}
.marso-icon-search1:before {
  content: "\f002";
}
.marso-icon-user:before {
  content: "\f007";
}
.marso-icon-check:before {
  content: "\f00c";
}
.marso-icon-close:before {
  content: "\f00d";
}
.marso-icon-remove:before {
  content: "\f00d";
}
.marso-icon-times:before {
  content: "\f00d";
}
.marso-icon-lock:before {
  content: "\f023";
}
.marso-icon-pencil:before {
  content: "\f040";
}
.marso-icon-chevron-left:before {
  content: "\f053";
}
.marso-icon-chevron-right:before {
  content: "\f054";
}
.marso-icon-plus-circle:before {
  content: "\f055";
}
.marso-icon-minus-circle:before {
  content: "\f056";
}
.marso-icon-times-circle:before {
  content: "\f057";
}
.marso-icon-check-circle:before {
  content: "\f058";
}
.marso-icon-info-circle:before {
  content: "\f05a";
}
.marso-icon-exclamation-circle:before {
  content: "\f06a";
}
.marso-icon-exclamation-triangle:before {
  content: "\f071";
}
.marso-icon-warning:before {
  content: "\f071";
}
.marso-icon-chevron-up:before {
  content: "\f077";
}
.marso-icon-chevron-down:before {
  content: "\f078";
}
.marso-icon-upload:before {
  content: "\f093";
}
.marso-icon-square-o:before {
  content: "\f096";
}
.marso-icon-twitter:before {
  content: "\f099";
}
.marso-icon-square:before {
  content: "\f0c8";
}
.marso-icon-caret-down:before {
  content: "\f0d7";
}
.marso-icon-caret-up:before {
  content: "\f0d8";
}
.marso-icon-caret-left:before {
  content: "\f0d9";
}
.marso-icon-caret-right:before {
  content: "\f0da";
}
.marso-icon-sort:before {
  content: "\f0dc";
}
.marso-icon-unsorted:before {
  content: "\f0dc";
}
.marso-icon-sort-desc:before {
  content: "\f0dd";
}
.marso-icon-sort-down:before {
  content: "\f0dd";
}
.marso-icon-sort-asc:before {
  content: "\f0de";
}
.marso-icon-sort-up:before {
  content: "\f0de";
}
.marso-icon-envelope:before {
  content: "\f0e0";
}
.marso-icon-angle-down:before {
  content: "\f107";
}
.marso-icon-circle-o:before {
  content: "\f10c";
}
.marso-icon-circle:before {
  content: "\f111";
}
.marso-icon-file-text:before {
  content: "\f15c";
}
.marso-icon-youtube:before {
  content: "\f167";
}
.marso-icon-youtube-play:before {
  content: "\f16a";
}
.marso-icon-recycle:before {
  content: "\f1b8";
}
